const menu = {
    profile: 'Profile',
    language: 'Language',
    appearance: {
        label: 'Appearance',
        light: 'Light mode',
        dark: 'Dark mode',
        system: 'Sync with system',
    },
    sign_out: 'Sign Out',
};
export default Object.freeze(menu);
