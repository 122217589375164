const dashboard = {
    page_title: 'Dashboard',
    title: 'Dashboard',
    description: 'Get an overview about your app performance',
    total_users: 'Total users',
    total_users_tip: 'Total users',
    new_users_today: 'New users today',
    new_users_today_tip: 'The number of new users registered on your apps today',
    new_users_7_days: 'New users past 7 days',
    new_users_7_days_tip: 'The number of new users registered on your apps in the past 7 days',
    daily_active_users: 'Daily active users',
    daily_active_users_tip: 'The number of unique users exchanged tokens on your apps today',
    weekly_active_users: 'Weekly active users',
    weekly_active_users_tip: 'The number of unique users exchanged tokens on your apps in the past 7 days',
    monthly_active_users: 'Monthly active users',
    monthly_active_users_tip: 'The number of unique users exchanged tokens on your apps in the past 30 days',
};
export default Object.freeze(dashboard);
