const demo_app = {
    title: "You've successfully signed in the live preview!",
    subtitle: 'Here is your user information:',
    username: 'Username: ',
    user_id: 'User ID: ',
    sign_out: 'Sign out the live preview',
    continue_explore: 'Or continue to explore',
    customize_sign_in_experience: 'Customize sign-in experience',
    enable_passwordless: 'Enable passwordless',
    add_social_connector: 'Add social connector',
};
export default Object.freeze(demo_app);
