const logs = {
    page_title: 'Audit logs',
    title: 'Audit logs',
    subtitle: 'View log data of authentication events made by your users',
    event: 'Event',
    user: 'User',
    application: 'Application',
    time: 'Time',
    filter_by: 'Filter by',
};
export default Object.freeze(logs);
